import React from "react";
import { getCloudinaryUrl } from "@website-builder/utilities/utils/CloudinaryUrlGenerator.js";
import { Separator } from "@website-builder/ui/shared/elements";
import { SectionWrapper } from "./styles";
export default ({
	children,
	hashLink = "",
	backgroundImage,
	backgroundColor,
	isSeparatorOnBottom,
}) => {
	let optimizedBackgroundImage = null;
	if (backgroundImage) {
		const [, cloudName, , source, version, ...pathParts] = backgroundImage
			.replace(/https:\/\/|http:\/\//gi, "")
			.split("/");

		const imagePath = pathParts.join("/").replace(/.webp|.jpg/g, ".png");

		const imageOptions = {
			quality: "auto",
			dpr: "auto",
			flags: "lossy",
			version,
			source,
			cloudName,
		};
		optimizedBackgroundImage = getCloudinaryUrl(imagePath, imageOptions);
	}
	return (
		<SectionWrapper
			data-background-image={optimizedBackgroundImage}
			data-background-delimiter=" "
			backgroundColor={backgroundColor}
			className="lozad"
			id={hashLink ? `${hashLink}` : ""}
			style={{}}
		>
			{children}
			{isSeparatorOnBottom ? <Separator className="separator" /> : null}
		</SectionWrapper>
	);
};

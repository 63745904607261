import styled from "styled-components";

export const SectionWrapper = styled.div`
	background-size: cover;
	${(props) =>
		props.backgroundColor && `background-color: ${props.backgroundColor};`}
	.separator {
		margin: 0 6rem;
	}
`;
